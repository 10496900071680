const HELP_ARTICLE_URL = 'https://pomoc.caterings.pl/article';

const EXTERNAL_URLS = {
  addons: `${HELP_ARTICLE_URL}/133-dodatki`,
  baskets: `${HELP_ARTICLE_URL}/228-koszyki`,
  daysOut: `${HELP_ARTICLE_URL}/356-wylaczanie-dni-z-zamowien`,
  deactivateDiet: `${HELP_ARTICLE_URL}/222-jak-dezaktywowac-diete-kiedy-dieta-jest-aktywna-dla-klientow`,
  foodsi: `${HELP_ARTICLE_URL}/344-foodsi-pakowanie-diety-do-kilku-toreb`,
  goodspeedIntegration: `${HELP_ARTICLE_URL}/286-integracja-z-goodspeed`,
  howDeliveryDiscountWorks: `${HELP_ARTICLE_URL}/137-jak-dziala-rabat-za-wspolna-dostawe`,
  invoiceConfig: `${HELP_ARTICLE_URL}/187-konfiguracja-fakturowni`,
  mailReminders: `${HELP_ARTICLE_URL}/240-przypomnienia-mailowe`,
  payUPaymentConfig: `${HELP_ARTICLE_URL}/132-konfiguracja-sklepu-payu`,
  recipes: `${HELP_ARTICLE_URL}/120-czym-jest-przepis-w-caterings`,
  timeSettings: `${HELP_ARTICLE_URL}/196-sloty-czasowe`,
  waysOfPackingBags: `${HELP_ARTICLE_URL}/80-sposoby-pakowania-toreb-z-caterings`,
  whyCustomerAddressDoesntHaveAssignedZone: `${HELP_ARTICLE_URL}/207-dlaczego-adres-klienta-nie-ma-przypisanej-strefy`,
  whyEndedDietIsStillActive: `${HELP_ARTICLE_URL}/225-dieta-sie-skonczyla-czemu-jest-aktywna`,
  zonesImport: `${HELP_ARTICLE_URL}/288-instrukcja-importu-stref`,
  mailNotifications: `${HELP_ARTICLE_URL}/239-powiadomienia-mailowe`,
  configurationOrderForm: `${HELP_ARTICLE_URL}/231-formularz-zamowienia`,
  discountCode: `${HELP_ARTICLE_URL}/271-kody-rabatowe`,
};

export default EXTERNAL_URLS;
